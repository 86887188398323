import * as Action from "@actions/index";

const InitialState = {
  tariffs: [
    {
      id: 1,
      name: "Базовый",
      price: {
        rub: 500,
        loyalty: 500,
      },
      newPrice: {
        rub: 1000,
        loyalty: 1000,
      },
      description: '<ul class="tariff__features"><li class="tariff__features-item">Доступ к всем онлайн-вебинарам</li><li class="tariff__features-item">Участие в розыгрыше приза по тарифу «Базовый»</li><li class="tariff__features-item">Диплом участника онлайн-интенсива по продаже зарубежного турпродукта в сезоне «Зима 24/25»</li><li class="tariff__features-item tariff__features-item--minus">Доступ к спейэфирам по продажам арабского люкса</li><li class="tariff__features-item tariff__features-item--minus">Доступ к архивным записям всех вебинаров</li><li class="tariff__features-item tariff__features-item--minus">Участие в розыгрыше приза по тарифу «Полный»</li></ul>',
    },
    {
      id: 2,
      name: "Полный",
      price: {
        rub: 2000,
        loyalty: 2000,
      },
      newPrice: {
        rub: 3000,
        loyalty: 3000,
      },
      description: '<ul class="tariff__features"><li class="tariff__features-item">Доступ к всем онлайн-вебинарам</li><li class="tariff__features-item">Доступ к спецэфирам по продажам арабского люкса</li><li class="tariff__features-item">Доступ к архивным записям всех вебинаров</li><li class="tariff__features-item">Участие в розыгрыше приза по тарифу «Полный»</li><li class="tariff__features-item">Диплом участника онлайн-интенсива по продаже зарубежного турпродукта в сезоне «Зима 24/25»</li></ul>',
    },
  ],
  promoDate: "2024-08-21T23:59:59+03:00",
  currentTariff: null,
  currentPayment: "loyalty",
  paymentLink: "",
};

export default function Payment(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Payment.SET_TARIFF:
      return { ...state, currentTariff: action.data };
    case Action.Payment.SET_PAYMENT:
      return { ...state, currentPayment: action.data };
    case Action.Payment.SET_PAYMENT_LINK:
      return { ...state, paymentLink: action.data };
    default:
      return state;
  }
}
